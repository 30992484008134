import styles from "./TestimonialSection.module.css"

const TestimonialSection = () => {
  return (
    <div className={styles.container}>
      <div className="widecontainer">
        <h1 className={styles.title}>
          “When employees truly understand their stake, it's not just ownership—it's belonging. That's how we transform
          companies from the inside out.”
        </h1>
        <p className={styles.subtitle}>Niya Dragova, CEO</p>
      </div>
    </div>
  )
}

export default TestimonialSection
