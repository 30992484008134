import Image from "next/image"
import { Button } from "components/core"
import styles from "./HeroSection.module.css"

type Props = {
  openContactModal: () => void
}

export const HeroSection = ({ openContactModal }: Props) => {
  return (
    <div className={styles.container}>
      <div className="widecontainer">
        <div className={styles.leftHalf}>
          <h1>
            Control the <span className={styles.highlight}>market impact</span> of stock compensation
          </h1>
          <p>
            Employees represent 20% or more of retail shareholders. Candor equips public companies with powerful capital
            markets controls for employee stock.
          </p>
          <Button type="primary" size="large" title="Get a demo →" shape="square" onClick={openContactModal} />
        </div>
        <div className={styles.rightHalf}>
          <Image
            src="/images/home/hero-laptop.png"
            alt="Control the market impact of stock compensation"
            width={851}
            height={549}
            priority={true}
          />
        </div>
      </div>
    </div>
  )
}
