import Image from "next/image"
import { useIsMobile } from "helpers/hooks/mediaQueryHook"
import styles from "./EmployeesSection.module.css"

const EmployeesSection = () => {
  const isMobile = useIsMobile()
  return (
    <div className={styles.container}>
      <div className="widecontainer">
        <h1 className={styles.title}>Employees are your most important retail shareholder class</h1>
        <p className={styles.content}>
          Employees can represent 20% or more of the retail shareholder base — Candor helps employees focus on the
          long-term and invest responsibly.
        </p>
        <div className={styles.userImageContainer}>
          <Image
            src="/images/home/hero-user.png"
            alt="Person holding a phone"
            width={isMobile ? 141 : 355}
            height={isMobile ? 171 : 430}
            priority={true}
          />
          <div className={styles.cardTop}>
            <div className={styles.cardTitle}>1-5%</div>
            <p>are executives</p>
          </div>
          <div className={styles.cardMiddle}>
            <p>the remaining are retail shareholders</p>
          </div>
          <div className={styles.cardBottom}>
            <div className={styles.cardTitle}>21.5%</div>
            <p>are employees</p>
          </div>
        </div>
        <div className={styles.disclaimer}>
          Based on 10-K, 10-Q, proxy disclosures and 13F fillings. Past performance does not guarantee future results
          and the likelihood of investment outcomes are speculative in nature. Results will depend on your individual
          factors and circumstances, including your personal investment decision-making.
        </div>
      </div>
    </div>
  )
}

export default EmployeesSection
