import { useRef, useState, useEffect } from "react"
import clsx from "clsx"
import { Button } from "components/core"
import ArrowIcon from "vector/icons/arrow-right.svg"
import VolatilityControlImg from "vector/illustrations/home/volatility-control.svg"
import AdvLimitImg from "vector/illustrations/home/adv-limit.svg"
import TradeExecutionImg from "vector/illustrations/home/trade-execution-algorithm.svg"
import SellToCoverImg from "vector/illustrations/home/sell-to-cover.svg"
import ExecPeerImg from "vector/illustrations/home/exec-peer-benchmarking.svg"
import TradeTimingProtectionImg from "vector/illustrations/home/trade-timing-protection.svg"
import StockBuybacksImg from "vector/illustrations/home/stock-buybacks.svg"
import InsiderMonitoringImg from "vector/illustrations/home/insider-trade-press-monitoring.svg"
import styles from "./CfoSuiteSection.module.css"

type Props = {
  openContactModal: () => void
}

const CfoSuiteSection = ({ openContactModal }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [isScrolledLeft, setIsScrolledLeft] = useState(true)
  const [isScrolledRight, setIsScrolledRight] = useState(false)

  const checkScrollPosition = () => {
    if (contentRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = contentRef.current
      setIsScrolledLeft(scrollLeft === 0)
      setIsScrolledRight(scrollLeft + clientWidth >= scrollWidth)
    }
  }

  const scrollLeft = () => {
    if (contentRef.current) {
      contentRef.current.scrollBy({ left: -200, behavior: "smooth" })
    }
  }

  const scrollRight = () => {
    if (contentRef.current) {
      contentRef.current.scrollBy({ left: 200, behavior: "smooth" })
    }
  }

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.addEventListener("scroll", checkScrollPosition)
      checkScrollPosition() // Initial check
    }
    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", checkScrollPosition)
      }
    }
  }, [])
  // TODO: rework carousel into a component

  return (
    <div className={styles.container}>
      <div className="widecontainer">
        <h4 className={styles.title}>For Chief Financial Officers</h4>
        <h1 className={styles.content}>CFO suite to predict and manage SBC market impact</h1>
        <div className={styles.carousel} ref={contentRef}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>Volatility control</div>
            <VolatilityControlImg />
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>ADV limit</div>
            <AdvLimitImg />
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>Sell-to-cover modeling</div>
            <SellToCoverImg />
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>Trade execution algorithm</div>
            <TradeExecutionImg />
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>Exec peer benchmarking</div>
            <ExecPeerImg />
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>Trade timing protection</div>
            <TradeTimingProtectionImg />
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>10b-18 stock buybacks</div>
            <StockBuybacksImg />
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>Insider trade press monitoring </div>
            <InsiderMonitoringImg />
          </div>
        </div>
        <div className={styles.carouselArrows}>
          <div className={styles.arrowContainer}>
            <ArrowIcon className={clsx(styles.arrowIconLeft, isScrolledLeft && styles.disabled)} onClick={scrollLeft} />
          </div>
          <div className={styles.arrowContainer}>
            <ArrowIcon className={clsx(styles.arrowIcon, isScrolledRight && styles.disabled)} onClick={scrollRight} />
          </div>
        </div>
        <Button type="primary" size="large" title="Get a demo →" shape="square" onClick={openContactModal} />
      </div>
    </div>
  )
}

export default CfoSuiteSection
