import clsx from "clsx"
import { useEffect, useRef, useState } from "react"
import WashSaleImg from "vector/illustrations/home/wash-sale-scheduling.svg"
import PlanScheduleImg from "vector/illustrations/home/10b51-plan-schedule.svg"
import TaxOptimizerImg from "vector/illustrations/home/tax-optimizer.svg"
import EducationVideoImg from "vector/illustrations/home/education-video.svg"
import FinancialGoalImg from "vector/illustrations/home/financial-goal.svg"
import LiquidityPlanningImg from "vector/illustrations/home/liquidity-planning.svg"
import CheckmarkIcon from "vector/icons/checkmark-full-circle.svg"
import ArrowIcon from "vector/icons/arrow-right.svg"
import styles from "./EmployeeBenefitsSection.module.css"

const EmployeeBenefitsSection = () => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [isScrolledLeft, setIsScrolledLeft] = useState(true)
  const [isScrolledRight, setIsScrolledRight] = useState(false)

  const checkScrollPosition = () => {
    if (contentRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = contentRef.current
      setIsScrolledLeft(scrollLeft === 0)
      setIsScrolledRight(scrollLeft + clientWidth >= scrollWidth)
    }
  }

  const scrollLeft = () => {
    if (contentRef.current) {
      contentRef.current.scrollBy({ left: -200, behavior: "smooth" })
    }
  }

  const scrollRight = () => {
    if (contentRef.current) {
      contentRef.current.scrollBy({ left: 200, behavior: "smooth" })
    }
  }

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.addEventListener("scroll", checkScrollPosition)
      checkScrollPosition() // Initial check
    }
    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", checkScrollPosition)
      }
    }
  }, [])
  return (
    <div className={styles.container}>
      <div className="widecontainer">
        <h1 className={styles.title}>Help employees be stewards of the company</h1>
        <p className={styles.subtitle}>
          Provide employees access to financial planning, education and <br />
          tax planning — to help employees become better long-term investors
        </p>
        <div className={styles.content} ref={contentRef}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>Affirmative defense</div>
            <p>Offer affirmative defense to every employee, regardless of rank.</p>
            <PlanScheduleImg className={styles.planImg} />
          </div>
          <div className={clsx(styles.card, styles.wide)}>
            <div className={styles.cardTitle}>Tax planning</div>
            <p>
              Full tax support for vesting, IPOs and <br />
              liquidations — complete with TurboTax integration.
            </p>
            <WashSaleImg className={styles.washSaleImg} />
            <div className={styles.pillsContainer}>
              <div className={styles.pill}>
                <CheckmarkIcon className={styles.checkMark} />
                Tax-free rebalancing
              </div>
              <div className={styles.pill}>
                <CheckmarkIcon className={styles.checkMark} />
                Capital gains optimizer
              </div>
              <div className={styles.pill}>
                <CheckmarkIcon className={styles.checkMark} />
                Wash sale scheduler
              </div>
            </div>
          </div>
          <div className={clsx(styles.card, styles.wide)}>
            <div className={styles.cardTitle}>Vest Tax Optimizer</div>
            <p>Automated tax optimizations, helping employees increase take home pay</p>
            <TaxOptimizerImg className={styles.taxOptimizerImg} />
          </div>
          <div className={clsx(styles.card, styles.green)}>
            <div className={styles.cardTitle}>Shareholder education</div>
            <p>400+ articles, courses and videos to help everyone feel confident.</p>
            <EducationVideoImg className={styles.educationVideoImg} />
          </div>
          <div className={clsx(styles.card, styles.mid)}>
            <div className={styles.cardTitle}>Financial planning</div>
            <p>Help employees plan for big financial goals - buying a house, welcoming a child and more.</p>
            <FinancialGoalImg className={styles.financialGoalImg} />
          </div>
          <div className={clsx(styles.card, styles.mid)}>
            <div className={styles.cardTitle}>IPO liquidity planning</div>
            <p>Comprehensive suite to help your whole shareholder base get ready for an IPO.</p>
            <LiquidityPlanningImg className={styles.liquidityPlanningImg} />
          </div>
        </div>
        <div className={styles.scrollContainer}>
          <ArrowIcon className={clsx(styles.arrowIconLeft, isScrolledLeft && styles.disabled)} onClick={scrollLeft} />
          <ArrowIcon className={clsx(styles.arrowIcon, isScrolledRight && styles.disabled)} onClick={scrollRight} />
        </div>
      </div>
    </div>
  )
}

export default EmployeeBenefitsSection
