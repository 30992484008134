import Image from "next/image"
import { Button } from "components/core"
import CheckmarkIcon from "vector/icons/check_icon.svg"
import { useIsMobile } from "helpers/hooks/mediaQueryHook"
import styles from "./GeneralCounselsSection.module.css"

type Props = {
  openContactModal: () => void
}

const GeneralCounselsSection = ({ openContactModal }: Props) => {
  const isMobile = useIsMobile()
  return (
    <div className={styles.container}>
      <div className="widecontainer">
        <div className={styles.left}>
          <h4 className={styles.title}>For General Counsels</h4>
          <h1 className={styles.contentTitle}>Get a handle on insider share activity</h1>
          <div className={styles.content}>
            <p>
              <CheckmarkIcon />
              Governance suite tracking all insiders
            </p>
            <p>
              <CheckmarkIcon />
              Live-updating case law controls
            </p>
            <p>
              <CheckmarkIcon />
              SEC form filing automation
            </p>
            <p>
              <CheckmarkIcon />
              Control insider shares at any brokerage
            </p>
            <p>
              <CheckmarkIcon />
              Broad base 10b5-1
            </p>
            <p>
              <CheckmarkIcon />
              Statistical risk modeling
            </p>
          </div>
          <Button type="primary" size="large" title="Get a demo →" shape="square" onClick={openContactModal} />
        </div>
        <Image
          src="/images/home/form4-calendar.png"
          alt="Form 4 filings calendar"
          width={520}
          height={520}
          priority={true}
          className={styles.formImage}
        />
      </div>
    </div>
  )
}

export default GeneralCounselsSection
