import Image from "next/image"
import { useState } from "react"
import analytics from "helpers/analytics"
import styles from "./TradingEnforcementTrendsSection.module.css"
import { Button } from "../core"

const TradingEnforcementTrendsSection = () => {
  const [formState, setFormState] = useState(null)
  const [emailText, setEmailText] = useState("")

  const onSubmit = async (e) => {
    e.preventDefault()
    setFormState("loading")
    analytics.track("Whitepaper requested (10b5-1)", { user: { email: emailText } })
    setFormState("success")
  }
  return (
    <div className={styles.container}>
      <div className="widecontainer">
        <Image src="/images/home/whitepaper-cover.png" alt="Whitepaper cover" width={146} height={205} />
        <div>
          <h1 className={styles.title}>Read the latest on SEC insider trading enforcement trends</h1>
          <form className={styles.inputContainer} onSubmit={onSubmit}>
            <input
              type="email"
              value={emailText}
              onChange={(e) => setEmailText(e.target.value)}
              placeholder="Your email"
              required
            />
            <Button
              type="primary"
              size="default"
              title="Get my copy"
              shape="square"
              onClick={onSubmit}
              disabled={formState !== null}
              loading={formState === "loading"}
              className={styles.ctaButton}
            />
          </form>

          {formState === "success" && (
            <div className={styles.formResponse}>Got it! The whitepaper is on its way to your inbox</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TradingEnforcementTrendsSection
